import './App.css';
import {HashRouter, Route, Routes, Navigate} from 'react-router-dom'
import Navegacion from './components/Navegacion.jsx';
import Home from './components/Home.jsx';
import ProfesionalPresentation from './components/Presentacion.jsx';
import Tratamientos from './components/Tratamientos.jsx';
import GestionTurnos from './components/GestionTurnos.jsx';
import Agendar from './components/Agendar.jsx';
import Reprogramar from './components/Reprogramar.jsx';
import Cancelar from './components/Cancelar.jsx';
import Footer from './components/Footer.jsx';
import SuccessPage from './components/SuccessPage.jsx';
import FailurePage from './components/FailurePage.jsx';

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Navegacion/>
        <div className="divBody">
            <Routes>
              <Route path = '/home' element = {<Home/>} />
              <Route path = '/sobre-mí' element = {<ProfesionalPresentation/>} />
              <Route path = '/experiencias' element = {<Tratamientos/>} />
              <Route path = '/gestionar-turno' element = {<GestionTurnos/>} />
              <Route path= '/agendar' element = {<Agendar/>}/>
              <Route path= '/reprogramar' element = {<Reprogramar/>}/>
              <Route path= '/cancelar' element = {<Cancelar/>}/>
              <Route path= '/success' element = {<SuccessPage/>}/>
              <Route path= '/failure' element = {<FailurePage/>}/>
              <Route path = '*' element = {<Navigate to= '/home' replace />} />
            </Routes>
        </div>
        <Footer/>
      </HashRouter>
      
    </div>
  );
}

export default App;
