import ad1 from "../images/tratamientos/ad1.jpg";
import ad2 from "../images/tratamientos/ad2.jpg";
import ad3 from "../images/tratamientos/ad3.jpg";
import ad4 from "../images/tratamientos/ad4.jpg";
import ad5 from "../images/tratamientos/ad5.jpg";
import ad6 from "../images/tratamientos/ad6.jpg";
import ad7 from "../images/tratamientos/ad7.jpg";
import ad8 from "../images/tratamientos/ad8.jpg";
import "../Texto.css";

function Tratamientos() {

  const anchoPantalla = window.innerWidth

  const tratamientoStyle = {
    maxWidth: "800px",
    margin: "auto",
    textAlign: "center",
    paddingTop: "40px",
  };

  const tratamientoContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "10px",
  };

  const imagenStyle = {
    width: "100%",
    maxWidth: "650px",
    height: "650px", // Establecer una altura fija
    borderRadius: "8px",
    marginBottom: "10px",
  };

  const imagenResponsiveStyle = {
    width: "100%",
    maxWidth: "650px",
    borderRadius: "8px",
    marginBottom: "10px",
  };

  const listItemStyle = {
    fontSize: "1.2em",
  };

  return (
    <>
    {anchoPantalla >= 800 ? (
      <div style={{ backgroundColor: "#CECECE" }}>
      <h2 className="roboto-titulo" style={{ paddingTop: "40px" }}>Experiencias de Pacientes</h2>
      <div style={{ display: "flex" }}>
        <div style={tratamientoStyle}>
          <div style={tratamientoContainerStyle}>
            <img
              src={ad1}
              alt="Antes y despues del Tratamiento"
              style={imagenStyle}
            />
            <div style={{ maxWidth: "600px", textAlign: "justify" }}>
              <h4 className="roboto-titulo">Descripción del Tratamiento:</h4>
              <h5 className="roboto-texto">
                Paciente con apnea del sueño y problemas respiratorios como
                consecuencia de la obesidad mórbida; con nuestro tratamiento
                mejoró estas consecuencias en pocos meses.
              </h5>
            </div>
          </div>
        </div>

        <div style={tratamientoStyle}>
          <div style={tratamientoContainerStyle}>
            <img
              src={ad2}
              alt="Antes y despues del Tratamiento"
              style={imagenStyle}
            />
            <div style={{ maxWidth: "600px", textAlign: "justify" }}>
              <h4 className="roboto-titulo">Descripción del Tratamiento:</h4>
              <h5 className="roboto-texto">
                Adulto joven con malos hábitos alimentarios; tratamiento para
                descenso de peso y educación en hábitos saludables.
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div style={{ display: "flex" }}>
        <div style={tratamientoStyle}>
          <div style={tratamientoContainerStyle}>
            <img
              src={ad3}
              alt="Antes y despues del Tratamiento"
              style={imagenStyle}
            />
            <div className="roboto-texto" style={{ maxWidth: "600px", textAlign: "justify" }}>
              <h4 className="roboto-titulo">Descripción del Tratamiento:</h4>
              <h5 className="roboto-texto">
                Obesidad en adulto joven; como consecuencia de esta, el paciente
                presentaba problemas articulares al inicio del tratamiento,
                situación que soluciono con el descenso de peso y grasa
                corporal.
              </h5>
            </div>
          </div>
        </div>

        <div style={tratamientoStyle}>
          <div style={tratamientoContainerStyle}>
            <img
              src={ad4}
              alt="Antes y despues del Tratamiento"
              style={imagenStyle}
            />
            <div style={{ maxWidth: "600px", textAlign: "justify" }}>
              <h4 className="roboto-titulo">Descripción del Tratamiento:</h4>
              <h5 className="roboto-texto">
                Paciente obesidad grado 2 en adulto joven; se trabajaron cambios
                en hábitos alimentarios por video consultas logrando su peso
                sano, que mantiene al día de la fecha.
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div style={{ display: "flex" }}>
        <div style={tratamientoStyle}>
          <div style={tratamientoContainerStyle}>
            <img
              src={ad5}
              alt="Antes y despues del Tratamiento"
              style={imagenStyle}
            />
            <div style={{ maxWidth: "600px", textAlign: "justify" }}>
              <h4 className="roboto-titulo">Descripción del Tratamiento:</h4>
              <h5 className="roboto-texto">
                Paciente con reganancia de peso después de by pass gástrico.
              </h5>
            </div>
          </div>
        </div>

        <div style={tratamientoStyle}>
          <div style={tratamientoContainerStyle}>
            <img
              src={ad6}
              alt="Antes y despues del Tratamiento"
              style={imagenStyle}
            />
            <div style={{ maxWidth: "600px", textAlign: "justify" }}>
              <h4 className="roboto-titulo">Descripción del Tratamiento:</h4>
              <h5 className="roboto-texto">
                Adulto joven con malos hábitos alimentarios; tratamiento para
                descenso de peso y educación en hábitos saludables.
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div style={{ display: "flex" }}>
        <div style={tratamientoStyle}>
          <div style={tratamientoContainerStyle}>
            <img
              src={ad7}
              alt="Antes y despues del Tratamiento"
              style={imagenStyle}
            />
            <div style={{ maxWidth: "600px", textAlign: "justify" }}>
              <h4 className="roboto-titulo">Descripción del Tratamiento:</h4>
              <h5 className="roboto-texto">
                Paciente con diabetes tipo 2 en tratamiento con planes
                alimentarios y educación en hábitos saludables y conteo de
                carbohidratos.
              </h5>
            </div>
          </div>
        </div>

        <div style={tratamientoStyle}>
          <div style={tratamientoContainerStyle}>
            <img
              src={ad8}
              alt="Antes y despues del Tratamiento"
              style={imagenStyle}
            />
            <div style={{ maxWidth: "600px", textAlign: "justify" }}>
              <h4 className="roboto-titulo">Descripción del Tratamiento:</h4>
              <h5 className="roboto-texto">
                Paciente con obesidad e insulinoresistencia; tratamiento con
                plan alimentario, ejercicio y medicación en camino a su peso
                saludable y a la corrección de la insulinoresistencia.
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    ): (
      <div style={{backgroundColor: "#CECECE"}}>
    <div style={tratamientoStyle}>
      <h2 className="roboto-titulo" style={{marginBottom: '40px'}}>Experiencias de Pacientes</h2>
      <div style={tratamientoContainerStyle}>
        <img
          src={ad1}
          alt="Antes y despues del Tratamiento"
          style={imagenResponsiveStyle}
        />
        <div style={{ maxWidth: '600px', textAlign: 'justify' }}>
          <h5 className="roboto-titulo">Descripción del Tratamiento:</h5>
          <h6 className="roboto-texto">
          Paciente con apnea del sueño y problemas respiratorios como consecuencia de la obesidad mórbida; 
          con nuestro tratamiento mejoró estas consecuencias en pocos meses.
          </h6>
        </div>
      </div>
    </div>

    <div style={tratamientoStyle}>
      <div style={tratamientoContainerStyle}>
        <img
          src={ad2}
          alt="Antes y despues del Tratamiento"
          style={imagenResponsiveStyle}
        />
        <div style={{ maxWidth: '600px', textAlign: 'justify' }}>
          <h5 className="roboto-titulo">Descripción del Tratamiento:</h5>
          <h6 className="roboto-texto">
          Adulto joven con malos hábitos alimentarios; tratamiento para descenso de peso y educación en hábitos saludables.
          </h6>
        </div>
      </div>
    </div>

    <div style={tratamientoStyle}>
      <div style={tratamientoContainerStyle}>
        <img
          src={ad3}
          alt="Antes y despues del Tratamiento"
          style={imagenResponsiveStyle}
        />
        <div style={{ maxWidth: '600px', textAlign: 'justify' }}>
          <h5 className="roboto-titulo">Descripción del Tratamiento:</h5>
          <h6 className="roboto-texto">
          Obesidad en adulto joven; como consecuencia de esta, el paciente 
          presentaba problemas articulares al inicio del tratamiento, situación que soluciono con el descenso de peso y grasa corporal.
          </h6>
        </div>
      </div>
    </div>

    <div style={tratamientoStyle}>
      <div style={tratamientoContainerStyle}>
        <img
          src={ad4}
          alt="Antes y despues del Tratamiento"
          style={imagenResponsiveStyle}
        />
        <div style={{ maxWidth: '600px', textAlign: 'justify' }}>
          <h5 className="roboto-titulo">Descripción del Tratamiento:</h5>
          <h6 className="roboto-texto">
          Paciente obesidad grado 2 en adulto joven; se trabajaron cambios en hábitos alimentarios 
          por video consultas logrando su peso sano, que mantiene al día de la fecha.
          </h6>
        </div>
      </div>
    </div>

    <div style={tratamientoStyle}>
      <div style={tratamientoContainerStyle}>
        <img
          src={ad5}
          alt="Antes y despues del Tratamiento"
          style={imagenResponsiveStyle}
        />
        <div style={{ maxWidth: '600px', textAlign: 'justify' }}>
          <h5 className="roboto-titulo">Descripción del Tratamiento:</h5>
          <h6 className="roboto-texto">
          Paciente con reganancia de peso después de by pass gástrico.
          </h6>
        </div>
      </div>
    </div>

    <div style={tratamientoStyle}>
      <div style={tratamientoContainerStyle}>
        <img
          src={ad6}
          alt="Antes y despues del Tratamiento"
          style={imagenResponsiveStyle}
        />
        <div style={{ maxWidth: '600px', textAlign: 'justify' }}>
          <h5 className="roboto-titulo">Descripción del Tratamiento:</h5>
          <h6 className="roboto-texto">
          Adulto joven con malos hábitos alimentarios; tratamiento para descenso de peso y educación en hábitos saludables.
          </h6>
        </div>
      </div>
    </div>

    <div style={tratamientoStyle}>
      <div style={tratamientoContainerStyle}>
        <img
          src={ad7}
          alt="Antes y despues del Tratamiento"
          style={imagenResponsiveStyle}
        />
        <div style={{ maxWidth: '600px', textAlign: 'justify' }}>
          <h5 className="roboto-titulo">Descripción del Tratamiento:</h5>
          <h6 className="roboto-texto">
          Paciente con diabetes tipo 2 en tratamiento con planes alimentarios y educación en hábitos saludables y conteo de carbohidratos.
          </h6>
        </div>
      </div>
    </div>

    <div style={tratamientoStyle}>
      <div style={tratamientoContainerStyle}>
        <img
          src={ad8}
          alt="Antes y despues del Tratamiento"
          style={imagenResponsiveStyle}
        />
        <div style={{ maxWidth: '600px', textAlign: 'justify' }}>
          <h5 className="roboto-titulo">Descripción del Tratamiento:</h5>
          <h6 className="roboto-texto">
          Paciente con obesidad e insulinoresistencia; tratamiento con plan alimentario, 
          ejercicio y medicación en camino a su peso saludable y a la corrección de la insulinoresistencia.
          </h6>
        </div>
      </div>
    </div>
    </div>
    )}
  </>
    
  );
}

export default Tratamientos;
