import FotoProfesional from "../images/foto-profesional.jpeg";
import Diploma from "../images/diploma.jpeg";
import { useEffect, useState } from "react";
import "../Texto.css";

function ProfesionalPresentation() {

  const [display, setDisplay] = useState("")

  useEffect(()=> {
    if(window.innerWidth >= 500){
      setDisplay("flex")
    }
  }, [])

  const containerStyle = {
    margin: "auto",
    textAlign: "center",
    paddingTop: "40px",
    backgroundColor: "#CECECE",
    color: "#363636",
  };

  const headerStyle = {
    marginBottom: "20px",
    display: display,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: '10px',
    paddingRight: '10px'
  };

  const contentContainerStyle = {
    display: "flex",
    justifyContent: "center",
    paddingLeft: '10px',
    paddingRight: '10px'
  };

  const imageStyle = {
    borderRadius: "50%",
    width: "300px",
    height: "300px",
    marginRight: "30px",
  };

  const presentationStyle = {
    maxWidth: "1000px",
    textAlign: "justify",
  };

  const diplomaStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  return (
    <div style={containerStyle}>
      <div style={headerStyle}>
        <img
          src={FotoProfesional}
          alt="Foto del Profesional"
          style={imageStyle}
        />
        <div className="roboto-titulo" style={{ marginLeft: display, marginTop: "10px" }}>
          <h1>Natalia Paola Perez</h1>
          <h2>Licenciada en Nutrición</h2>
          <h2>Matricula: M.P 2094</h2>
        </div>
      </div>

      <div style={contentContainerStyle}>
        <div style={presentationStyle}>
          <h3 className="roboto-titulo" style={{ borderBottom: "2px solid #363636" }}>
            Filosofía profesional:
          </h3>
          <h5 className="roboto-texto" style={{ marginBottom: "30px" }}>
            En mi práctica como licenciada en nutrición, abrazo la filosofía de
            nutrir no solo el cuerpo, sino también el bienestar integral de cada
            individuo. Creo en la importancia de adoptar enfoques
            personalizados, centrándome en comprender las necesidades únicas de
            cada cliente. Mi compromiso va más allá de proporcionar simples
            planes alimentarios; busco empoderar a mis clientes con
            conocimientos sobre hábitos alimenticios saludables y equilibrados.
            Mis recomendaciones se basan en la ciencia nutricional más reciente,
            adaptadas a las preferencias y necesidades específicas de cada
            persona.
          </h5>

          <h3 className="roboto-titulo" style={{ borderBottom: "2px solid #363636" }}>Servicios:</h3>

          <h5 className="roboto-texto">- Planes alimentarios adecuados a tu objetivo</h5>
          <h5 className="roboto-texto">- Tratamientos obesidad</h5>
          <h5 className="roboto-texto">- Asesoramiento nutricional</h5>
          <h5 className="roboto-texto">- Alimentación y suplementación deportiva</h5>
          <h5 className="roboto-texto">- Especialista en diabetes</h5>
        </div>
      </div>
      <div style={diplomaStyle}>
        <img
          src={Diploma}
          alt="Diploma"
          style={{
            width: "300px",
            height: "400px",
            transform: "rotate(-90deg)",
          }}
        />
      </div>
    </div>
  );
}

export default ProfesionalPresentation;
