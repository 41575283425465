import Consejos from "./Consejos";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import "../Texto.css";

function Home() {
  const divStyle = {
    display: "flex",
    maxWidth: "1200px",
    margin: "auto",
  };

  const sectionStyle = {
    flex: 1, // Cada sección ocupará una parte igual del espacio disponible
    backgroundColor: "#FF83FD", // Puedes cambiar este color a tu preferencia
    padding: "20px", // Espaciado interno
    color: "#363636", // Color del texto (blanco en este caso)
    fontSize: "25px", // Tamaño del texto
    width: "calc(100.3% - 0px)",
  };

  const iconStyle = {
    marginRight: "10px",
  };

  const infoContainerStyle = {
    display: "flex",
    alignItems: "center",
  };

  const anchoPantalla = window.innerWidth

  return (
    <div style={{ backgroundColor: "#CECECE", padding: "20px" }}>
      <Consejos />
      {anchoPantalla >= 598 ? (
        <div style={divStyle}>
        <div style={{ ...sectionStyle, marginLeft: "10px" }}>
          {/* Contenido de la primera sección */}
          <div className="roboto-titulo" style={{ marginBottom: "10px", fontWeight: 600 }}>
            Consultorios
          </div>
          <div style={{ ...infoContainerStyle, justifyContent: "center" }}>
            <FontAwesomeIcon icon={faPhone} style={iconStyle} />
            <h6 className="roboto-texto">+54 9 351 508-7188</h6>
          </div>
          <div style={{ ...infoContainerStyle, justifyContent: "center" }}>
            <FontAwesomeIcon icon={faLocationDot} style={iconStyle} />
            <h6 className="roboto-texto">Venturini 55 - Colonia Caroya</h6>
          </div>
          <div style={{ ...infoContainerStyle, justifyContent: "center" }}>
            <FontAwesomeIcon icon={faLocationDot} style={iconStyle} />
            <h6 className="roboto-texto">Salta 801 - Jesus Maria</h6>
          </div>
        </div>
        <div
          style={{ ...sectionStyle, marginRight: "10px", marginLeft: "10px" }}
        >
          {/* Contenido de la segunda sección */}
          <div className="roboto-titulo" style={{ marginBottom: "10px", fontWeight: 600 }}>
            Consultas Virtuales
          </div>
          <div className="roboto-texto">
            <Button href="#gestionar-turno" variant="light" size="lg">
              Agendar
            </Button>{" "}
          </div>
        </div>
        <div style={{ ...sectionStyle, marginRight: "10px" }}>
          {/* Contenido de la tercera sección */}
          <div className="roboto-titulo" style={{ marginBottom: "10px", fontWeight: 600 }}>
            Nuestros Clientes
          </div>
          <div className="roboto-texto">
            <Button href="#experiencias" variant="light" size="lg">
              Experiencias
            </Button>{" "}
          </div>
        </div>
      </div>
      ): <div style={{
        maxWidth: "1200px",
        margin: "auto",
      }}>
      <div style={{ ...sectionStyle, marginTop: "10px" }}>
        {/* Contenido de la primera sección */}
        <div style={{ marginBottom: "10px", fontWeight: 600 }}>
          Consultorios
        </div>
        <div style={{ ...infoContainerStyle, justifyContent: "center" }}>
          <FontAwesomeIcon icon={faPhone} style={iconStyle} />
          <h6>+54 9 351 508-7188</h6>
        </div>
        <div style={{ ...infoContainerStyle, justifyContent: "center" }}>
          <FontAwesomeIcon icon={faLocationDot} style={iconStyle} />
          <h6>Venturini 55 - Colonia Caroya</h6>
        </div>
        <div style={{ ...infoContainerStyle, justifyContent: "center" }}>
          <FontAwesomeIcon icon={faLocationDot} style={iconStyle} />
          <h6>Salta 801 - Jesus Maria</h6>
        </div>
      </div>
      <div
        style={{ ...sectionStyle, marginTop: "10px" }}
      >
        {/* Contenido de la segunda sección */}
        <div style={{ marginBottom: "10px", fontWeight: 600 }}>
          Consultas Virtuales
        </div>
        <div>
          <Button href="#gestionar-turno" variant="light" size="lg">
            Agendar
          </Button>{" "}
        </div>
      </div>
      <div style={{ ...sectionStyle, marginTop: "10px" }}>
        {/* Contenido de la tercera sección */}
        <div style={{ marginBottom: "10px", fontWeight: 600 }}>
          Nuestros Clientes
        </div>
        <div>
          <Button href="#experiencias" variant="light" size="lg">
            Experiencias
          </Button>{" "}
        </div>
      </div>
    </div>}
      
    </div>
  );
}

export default Home;
