import Carousel from 'react-bootstrap/Carousel';
import Fondo from '../images/consejos/fondo5.jpeg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';

function Consejos() {
  const customArrowStyle = {
    fontSize: '50px',  // Tamaño de la fuente para ajustar el tamaño de las flechas
    color: 'black',    // Color de las flechas
    cursor: 'pointer', // Cambia el cursor al pasar sobre las flechas
    position: 'absolute',
    top: '50%',        // Alinea verticalmente al centro
    transform: 'translateY(-50%)', // Alinea verticalmente al centro
  };

  const captionStyle = {
    position: 'absolute',
    top: '38%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    color: '#000000',
    fontFamily: "'Satisfy', cursive",
    width: '100%', // Asegura que el contenido ocupe todo el ancho
    padding: '55px',
  };

  const imageStyle = {
    height: '470px',
    width: '100%',
    objectFit: 'cover', // Asegura que la imagen cubra todo el contenedor sin distorsionarse
  };

  return (
    <div style={{ maxWidth: '1200px', margin: 'auto' }}>
      <div style={{ marginTop: '0px', marginBottom: '20px' }}>
        <Carousel data-bs-theme="dark" nextIcon={<FontAwesomeIcon icon={faCaretRight} style={{ ...customArrowStyle, right: '30' }} />} prevIcon={<FontAwesomeIcon icon={faCaretLeft} style={{ ...customArrowStyle, left: '30' }} />}>
          <Carousel.Item>
            <img
              className="d-block mx-auto"
              src={Fondo}
              alt="First slide"
              style={imageStyle}
            />
            <Carousel.Caption style={captionStyle}>
              <h2>Recuerda:</h2>
              <h4>"Cuando la ALIMENTACION es mala, la medicina no funciona; cuando la ALIMENTACION es buena la medicina no es necesaria"</h4>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block mx-auto"
              src={Fondo}
              alt="Second slide"
              style={imageStyle}
            />
            <Carousel.Caption style={captionStyle}>
              <h2>La voluntad es el músculo más fuerte</h2>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block mx-auto"
              src={Fondo}
              alt="Third slide"
              style={imageStyle}
            />
            <Carousel.Caption style={captionStyle}>
              <h2>¡Los hábitos importan!</h2>
              <h4>"Empiece por cambiar hábitos poco saludables por otros saludables, y conviértalos en sus favoritos"</h4>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
}

export default Consejos;
