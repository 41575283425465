import { Navbar, Container, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import "../Texto.css";

function Footer() {
  return (
    <>
      {/* Contenido del cuerpo de la página */}
      
      {/* Pie de página */}
      <Navbar className="roboto-texto" variant="dark" style={{ backgroundColor: '#C517D7', marginTop: 'auto', paddingTop: '10px' }}>
        <Container>
          <Nav className="mx-auto">
            <div style={{ marginTop: '10px' }}>
              <FontAwesomeIcon icon={faFacebook} style={{color: 'white'}}/>
            </div>
            <Nav.Link href="https://www.facebook.com/profile.php?id=100075758178578&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer" 
            style={{ marginBottom: 0, fontSize: '115%' }}>Facebook</Nav.Link>
            <div style={{ marginTop: '10px' }}>
              <FontAwesomeIcon icon={faWhatsapp} style={{color: 'white'}}/>
            </div>
            <Nav.Link href="https://api.whatsapp.com/send?phone=5493525653420&text=Hola,%20quiero%20hacer%20una%20consulta." target="_blank" rel="noopener noreferrer" 
            style={{ marginBottom: 0, fontSize: '115%' }}>Whatsapp</Nav.Link>
            <Nav.Link href="#https://www.freepik.com" style={{ marginBottom: 0, fontSize: '115%' }}>Images designed by Freepik</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default Footer;
