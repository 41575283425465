import { Button } from 'react-bootstrap';
import fondoSolicitud from '../images/nutricionista.jpg'
import fondoCancelar from '../images/cancelacion.jpg'
import fondoReprogramar from '../images/reloj-calendario.jpg'
import { useEffect, useState } from 'react';
import "../Texto.css";

function GestionTurnos() {

  const [display, setDisplay] = useState("")
  const [width, setWidth] = useState("")

  useEffect(() => {
    if(window.innerWidth >= 800){
      setDisplay("flex")
      setWidth("calc(33.33% - 20px)")
    }
  }, [])
  

  const containerStyle = {
    display: display,
    justifyContent: 'space-between',
    padding: '20px',
    backgroundColor: '#CECECE'
  };

  const sectionStyle = {
    position: 'relative',
    width: width,
    height: '500px',
    margin: '15px',
    marginBottom: '50px',
    marginTop: '5px',
    borderRadius: '10px',
    backgroundSize: 'cover', // Ajusta el tamaño de la imagen para cubrir completamente el fondo
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  };

  const squareStyle = {
    position: 'absolute',
    top: '100%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'calc(100.3% - 0px)',
    height: '100px',
    backgroundColor: '#FF5FFD', // Color de fondo del cuadro
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0px 0px 10px 10px',
  };

  return (
    <>
    {display === "flex" ? (
      <div className="roboto-texto" style={containerStyle}>
      <div style={{ ...sectionStyle, backgroundImage: `url(${fondoReprogramar})`, marginBottom: '70px' }}>
        <div style={squareStyle}>
          <Button variant='warning' href='#reprogramar'>Reprogramar Turno</Button>
        </div>
      </div>

      <div style={{ ...sectionStyle, backgroundImage: `url(${fondoSolicitud})`, marginBottom: '70px' }}>
        <div style={squareStyle}>
          <Button variant='success' href='#agendar'>Solicitar Turno</Button>
        </div>
      </div>

      <div style={{ ...sectionStyle, backgroundImage: `url(${fondoCancelar})`, marginBottom: '70px' }}>
        <div style={squareStyle}>
          <Button variant='danger' href='#cancelar'>Cancelar Turno</Button>
        </div>
      </div>
    </div>
    ): (
      <div className="roboto-texto" style={containerStyle}>
        <div style={{ ...sectionStyle, backgroundImage: `url(${fondoSolicitud})`, marginBottom: '70px' }}>
        <div style={squareStyle}>
          <Button variant='success' href='#agendar'>Solicitar Turno</Button>
        </div>
      </div>

      <div style={{ ...sectionStyle, backgroundImage: `url(${fondoReprogramar})`, marginBottom: '70px' }}>
        <div style={squareStyle}>
          <Button variant='warning' href='#reprogramar'>Reprogramar Turno</Button>
        </div>
      </div>

      <div style={{ ...sectionStyle, backgroundImage: `url(${fondoCancelar})`, marginBottom: '70px' }}>
        <div style={squareStyle}>
          <Button variant='danger' href='#cancelar'>Cancelar Turno</Button>
        </div>
      </div>
    </div>
    )}
    </>
  );
}

export default GestionTurnos;
