import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import icono from "../images/icono.png";
import Dropdown from 'react-bootstrap/Dropdown';
import "../Texto.css";

function Navegacion() {
  return (
    <>
      <Navbar className="roboto-texto" variant="dark" style={{ backgroundColor: '#C517D7', position: 'sticky', top: 0, zIndex: 1000 }}>
        <Container>
          <img
            src={icono}
            alt=""
            style={{ marginRight: '10px', height: '50px', width: '50px', borderRadius: '10%', position: 'relative', top: '-4px' }}
          />
          <Navbar.Brand href="#home" style={{ marginBottom: -10, fontSize: '140%', fontWeight: 650 }}>
            Natalia Perez
            <br />
            <small style={{ fontSize: '80%', position: 'relative', top: '-15px' }}>Lic. en Nutrición</small>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            {window.innerWidth < 768 ? (
              <Dropdown>
              <Dropdown.Toggle style={{backgroundColor: '#8E8E8E'}} id="dropdown-basic">
                
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#home" style={{ marginBottom: 0, fontSize: '115%' }}>Inicio</Dropdown.Item>
                <Dropdown.Item href="#sobre-mí" style={{ marginBottom: 0, fontSize: '115%' }}>Sobre Mí</Dropdown.Item>
                <Dropdown.Item href="#experiencias" style={{ marginBottom: 0, fontSize: '115%' }}>Experiencias</Dropdown.Item>
                <Dropdown.Item href="#gestionar-turno" style={{ marginBottom: 0, fontSize: '115%' }}>Gestionar Turno</Dropdown.Item>
              </Dropdown.Menu>
              </Dropdown>
            ) : (
                <Nav className="navbar navbar-expand-lg" style={{ marginLeft: 'auto' }}>
                  <Nav.Link href="#home" style={{ marginBottom: 0, fontSize: '115%', color: 'white' }}>Inicio</Nav.Link>
                  <Nav.Link href="#sobre-mí" style={{ marginBottom: 0, fontSize: '115%', color: 'white' }}>Sobre Mí</Nav.Link>
                  <Nav.Link href="#experiencias" style={{ marginBottom: 0, fontSize: '115%', color: 'white' }}>Experiencias</Nav.Link>
                  <Nav.Link href="#gestionar-turno" style={{ marginBottom: 0, fontSize: '115%', color: 'white' }}>Gestionar Turno</Nav.Link>
                </Nav>
              )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Navegacion;
