import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useState } from "react";
import TurnoService from "../services/turnos.services.js";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import "../Texto.css";

function Cancelar() {
  const navigate = useNavigate();
  const [cargando, setCargando] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleCancelar = () => {
    Swal.fire({
      text: "¿Seguro que quiere cancelar la operación?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        reset();
        navigate("/home");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const onSubmit = async (data) => {
    let error = false;

    if (data.hora === "") {
      error = true;
      Swal.fire({
        text: "Debe seleccionar un horario",
        icon: "warning",
        confirmButtonText: "Ok",
      });
    }

    if (!/^\d{8}$/.test(data.dni)) {
      error = true;
      Swal.fire({
        text: "El DNI ingresado no es válido",
        icon: "warning",
        confirmButtonText: "Ok",
      });
    }

    if (!/^[0-9]+$/.test(data.turnId)) {
      error = true;
      Swal.fire({
        text: "El ID del turno debe ser un número entero",
        icon: "warning",
        confirmButtonText: "Ok",
      });
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.mail)) {
      error = true;
      Swal.fire({
        text: "Debe ingresar un mail válido",
        icon: "warning",
        confirmButtonText: "Ok",
      });
    }

    if (!error) {
      Swal.fire({
        title: "¡Atención!",
        text: `Esta acción cancelará su turno`,
        input: "text",
        inputLabel: "Recuerde respetar las mayúsculas",
        inputPlaceholder: "Para continuar escribe 'CANCELAR'",
        inputAttributes: {
          autocomplete: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        showLoaderOnConfirm: true,
        preConfirm: async (input) => {
          if (input === "CANCELAR") {
            setCargando(true);

            const turnoOriginal = await TurnoService.TurnoGetByIdYDni(
              data.turnId,
              data.dni
            );

            console.log(turnoOriginal);

            if (
              turnoOriginal ===
                "No se ha encontrado el turno o la fecha del mismo ya pasó" ||
              turnoOriginal === "Error en el servidor"
            ) {
              setCargando(false);
              Swal.fire({
                text: turnoOriginal,
                icon: "error",
                confirmButtonText: "Ok",
              });
            } else {
              const turnoEliminado = await TurnoService.TurnoDelete(
                turnoOriginal.id,
                turnoOriginal.dni
              );
              if (
                turnoEliminado === "Error en el servidor" ||
                turnoEliminado === "El turno a eliminar no ha sido encontrado"
              ) {
                setCargando(false);
                Swal.fire({
                  text: turnoEliminado,
                  icon: "error",
                  confirmButtonText: "Ok",
                });
              } else {
                const message = `<!DOCTYPE html>
                            <html lang="es">
                            <head>
                                <meta charset="UTF-8">
                                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                                <title>Turno de Nutrición</title>
                                <style>
                                body {
                                    font-family: 'Arial', sans-serif;
                                }
                                .container {
                                    max-width: 600px;
                                    margin: 0 auto;
                                    padding: 20px;
                                    border: 1px solid #ddd;
                                }
                                h1 {
                                    color: #007BFF;
                                }
                                p {
                                    margin-bottom: 10px;
                                }
                                </style>
                            </head>
                            <body>
                                <div class="container">
                                <h1>Turno CANCELADO</h1>
                                <p><strong>ID del turno:</strong> ${turnoEliminado.id}</p>
                                <p><strong>DNI:</strong> ${turnoEliminado.dni}</p>
                                <p><strong>Correo electrónico de notificación:</strong> ${data.mail}</p>
                                <p><strong>Nombre del paciente:</strong> ${turnoEliminado.nombreCliente}</p>
                                <p><strong>Fecha del turno:</strong> ${turnoEliminado.fecha}</p>
                                <p><strong>Hora del turno:</strong> ${turnoEliminado.hora}</p>
                                <h4>Comunicarse por whatsapp para reintegro de pago</h4>
                                </div>
                            </body>
                            </html>`;

                const result = await TurnoService.Notification({
                  mail: data.mail,
                  htmlMessage: message,
                });

                setCargando(false);

                Swal.fire({
                  title: "Turno cancelado correctamente",
                  text: "Comuniquese por whatsapp para el reintegro",
                  icon: "success",
                  confirmButtonText: "Aceptar",
                  showLoaderOnConfirm: true,
                  preConfirm: async () => {
                    reset();
                    navigate("/home");
                  },
                });
              }
            }
          } else {
            Swal.fire({
              title: "¡Error!",
              text: 'No ha escrito "CANCELAR" correctamente. Recuerde las mayusculas',
              icon: "error",
              confirmButtonText: "Aceptar",
            });
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      });
    }
  };

  return (
    <div
      style={{
        alignItems: "center",
        justifyContent: "center",
        height: "80vh",
        textAlign: "center",
        padding: "30px",
      }}
    >
      {cargando && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 1000,
          }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <h3 className="roboto-titulo" style={{ paddingBottom: "60px" }}>
        Cancelar Turno
      </h3>
      <form
        style={{ paddingBottom: "140px" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div
          style={{
            gap: "7px",
            justifyContent: "space-between",
          }}
        >
          <div className="mb-3 roboto-texto">
            <label className="form-label">ID del turno:</label>
            <input
              style={{ maxWidth: "245px", margin: "auto" }}
              type="string"
              placeholder="(Enviado en el mail de registro)"
              className="form-control"
              {...register("turnId", { required: true })}
              autoComplete="off"
            />
            {errors.turnId && (
              <div
                className="alert alert-danger"
                role="alert"
                style={{ maxWidth: "207px", margin: "auto" }}
              >
                El ID del turno es un campo necesario
              </div>
            )}
          </div>
          <div className="mb-3 roboto-texto">
            <label className="form-label">DNI del paciente:</label>
            <input
              style={{ maxWidth: "245px", margin: "auto" }}
              type="string"
              className="form-control"
              {...register("dni", { required: true })}
              autoComplete="off"
            />
            {errors.dni && (
              <div
                className="alert alert-danger"
                role="alert"
                style={{ maxWidth: "207px", margin: "auto" }}
              >
                El DNI del paciente es un campo necesario
              </div>
            )}
          </div>

          <div className="mb-3 roboto-texto">
            <label className="form-label">Mail:</label>
            <input
              style={{ maxWidth: "245px", margin: "auto" }}
              type="string"
              placeholder="(Para notificación)"
              className="form-control"
              {...register("mail", { required: true })}
              autoComplete="off"
            />
            {errors.mail && (
              <div
                className="alert alert-danger"
                role="alert"
                style={{ maxWidth: "207px", margin: "auto" }}
              >
                El mail es un campo necesario
              </div>
            )}
          </div>
        </div>

        <div className="roboto-texto">
          <button type="submit" className="btn btn-success">
            Confirmar
          </button>
          <button
            type="button"
            className="btn btn-danger mx-2"
            onClick={handleCancelar}
          >
            Cancelar
          </button>
        </div>
      </form>
    </div>
  );
}
export default Cancelar;
