import React, { useEffect, useState } from 'react';
import TurnoService from '../services/turnos.services.js';
import "../Texto.css";

const SuccessPage = () => {
  const [turnoData, setTurnoData] = useState({
    turnId: '',
    dni: '',
    mail: '',
    nombreCompleto: '',
    edad: '',
    peso: '',
    altura: '',
    coment: '',
    disease: '',
    labs: '',
    date: '',
    hour: '',
  });

  const sendMail = async(turnId, dni, mail, nombreCompleto, edad, peso, altura, coment, disease, labs, date, hour) => {

    const message = `<!DOCTYPE html>
  <html lang="es">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Turno de Nutrición</title>
    <style>
      body {
        font-family: 'Arial', sans-serif;
      }
      .container {
        max-width: 600px;
        margin: 0 auto;
        padding: 20px;
        border: 1px solid #ddd;
      }
      h1 {
        color: #007BFF;
      }
      p {
        margin-bottom: 10px;
      }
    </style>
  </head>
  <body>
    <div class="container">
      <h1>Turno de Nutrición</h1>
      <p><strong>ID del turno:</strong> ${turnId}</p>
      <p><strong>DNI:</strong> ${dni}</p>
      <p><strong>Correo electrónico asosciado:</strong> ${mail}</p>
      <p><strong>Nombre del paciente:</strong> ${nombreCompleto}</p>
      <p><strong>Edad:</strong> ${edad}</p>
      <p><strong>Peso:</strong> ${peso}</p>
      <p><strong>Altura:</strong> ${altura}</p>
      <p><strong>Comentario:</strong> ${coment}</p>
      <p><strong>Enfermedades diagnosticadas:</strong> ${disease}</p>
      <p><strong>Laboratorios realizados:</strong> ${labs}</p>
      <p><strong>Fecha del turno:</strong> ${date}</p>
      <p><strong>Hora del turno:</strong> ${hour}</p>
      <h4>Comunicarse por whatsapp para recibir el link de la conferencia virtual</h4>
    </div>
  </body>
  </html>`

    const result = await TurnoService.Notification({
      mail: mail,
      htmlMessage: message
    })

    return result
  }

  useEffect(() => {
    // Obtener parámetros del fragmento de la URL
    const fragmentParams = window.location.hash.split('?')[1];
    const urlParams = new URLSearchParams(fragmentParams);

    // Obtener valores de los parámetros
    const turnId = urlParams.get('turnId') || '';
    const dni = urlParams.get('dni') || '';
    const mail = urlParams.get('mail') || '';
    const nombreCompleto = urlParams.get('nombreCompleto') || '';
    const edad = urlParams.get('edad') || '';
    const peso = urlParams.get('peso') || '';
    const altura = urlParams.get('altura') || '';
    const coment = urlParams.get('coment') || 'Sin comentarios';
    const disease = urlParams.get('disease') || 'Sin enfermedades';
    const labs = urlParams.get('labs') || 'Sin laboratorios';
    const date = urlParams.get('date') || '';
    const hour = urlParams.get('hour') || '';

    //MANDAR MAIL
    const mailResult = sendMail(turnId, dni, mail, nombreCompleto, edad, peso, altura, coment, disease, labs, date, hour)

    // Actualizar el estado con los datos de la URL
    setTurnoData({
      turnId,
      dni,
      mail,
      nombreCompleto,
      edad,
      peso,
      altura,
      coment,
      disease,
      labs,
      date,
      hour,
    });
  }, []);

  return (
    <div style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "80vh",
      textAlign: "center",
      padding: '10px'
    }}>
      <div>
      <h2 className="roboto-titulo" style={{paddingBottom: '20px', paddingTop: '20px'}}>¡Turno agendado con éxito!</h2>
      <h4 className="roboto-texto">Detalles del turno:</h4>
      <h5 className="roboto-texto">ID de turno: {turnoData.turnId}</h5>
      <h5 className="roboto-texto">DNI: {turnoData.dni}</h5>
      <h5 className="roboto-texto">Correo electrónico: {turnoData.mail}</h5>
      <h5 className="roboto-texto">Nombre completo: {turnoData.nombreCompleto}</h5>
      <h5 className="roboto-texto">Edad: {turnoData.edad}</h5>
      <h5 className="roboto-texto">Peso: {turnoData.peso}</h5>
      <h5 className="roboto-texto">Altura: {turnoData.altura}</h5>
      <h5 className="roboto-texto">Comentario: {turnoData.coment}</h5>
      <h5 className="roboto-texto">Enfermedades diagnosticadas: {turnoData.disease}</h5>
      <h5 className="roboto-texto">Estudios previos (laboratorios): {turnoData.labs}</h5>
      <h5 className="roboto-texto">Fecha del turno: {turnoData.date}</h5>
      <h5 className="roboto-texto">Hora del turno: {turnoData.hour}</h5>
      <h4 className="roboto-texto">Comunicarse por whatsapp para recibir el link de la conferencia virtual</h4>
      <h4 className="roboto-texto">Los detalles del turno fueron enviados por mail, por favor revisar spam</h4>
    </div>
    </div>
  );
};

export default SuccessPage;
