import { useEffect } from "react";
import TurnoService from "../services/turnos.services.js";
import "../Texto.css";

const FailurePage = () => {
  const deleteTurn = async (turnId, dni) => {
    const result = await TurnoService.TurnoDelete(turnId, dni);
    return result;
  };

  useEffect(() => {
    // Obtener parámetros de la URL
    const urlParams = new URLSearchParams(window.location.hash.split("?")[1]);

    // Obtener valores de los parámetros
    const turnId = urlParams.get("turnId") || "";
    const dni = urlParams.get("dni") || "";

    //ACA BORRO EL TURNO
    const deleteResult = deleteTurn(turnId, dni);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "80vh",
        textAlign: "center",
        padding: '10px'
      }}
    >
      <div>
        <h2 className="roboto-titulo">¡Hubo un problema!</h2>
        <h4 className="roboto-texto">Lo sentimos, ha ocurrido un error durante el proceso de pago.</h4>
        <h4 className="roboto-texto">Por favor, inténtelo nuevamente más tarde.</h4>
      </div>
    </div>
  );
};

export default FailurePage;
