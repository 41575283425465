import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import Datepicker from "./DatePicker";
import { useState, useEffect } from "react";
import TurnoService from "../services/turnos.services.js";
import { initMercadoPago, Wallet } from "@mercadopago/sdk-react";
import { useNavigate } from "react-router-dom";
import "../Texto.css";

function Agendar() {
  const publicKey = "APP_USR-6409d1d6-c684-4a15-a169-8b7e727e42b3"; //esta es la de la naty
  //const publicKey = "APP_USR-4fcd622c-8678-4f9c-a557-2d96c7c9fed6"; //esta es la de prueba
  initMercadoPago(publicKey);

  const navigate = useNavigate();

  //fecha del turno
  const [fecha, setFecha] = useState(null);

  const [idPreferencia, setIdPreferencia] = useState(null);

  const [turnosOcupados, setTurnosOcupados] = useState([]); //variable utilizada para determinar que fechas y horas estan tomadas.

  const [paddingComentario, setPaddingC] = useState("24px");
  const [paddingEnfermedades, setPaddingE] = useState("0px");

  const obtenerTurnos = async () => {
    const res = await TurnoService.TurnosGetAll();
    if (res !== "Error en el servidor") {
      setTurnosOcupados(res);
    } else {
      //AVISAR QUE EL SERVIDOR NO RESPONDE y redirigir
      Swal.fire({
        text: `${res}, por favor reintente más tarde.`,
        icon: "error",
        showCancelButton: false,
        confirmButtonText: "Aceptar",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          reset();
          navigate("/home");
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
    }
  };

  const eliminarTurno = async (idTurno, dniTurno) => {
    const turnoEliminado = await TurnoService.TurnoDelete(idTurno, dniTurno);
    return turnoEliminado;
  };

  useEffect(() => {
    if (window.innerWidth >= 715) {
      setPaddingC("0px");
    }
    if (window.innerWidth >= 715 && window.innerWidth <= 720) {
      setPaddingE("24px");
      setPaddingC("24px");
    }
    obtenerTurnos();
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  //seteo de fecha del DatePicker (formato previo para comparacion)
  const tomarFecha = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const fechaFormateada = `${year}-${month}-${day}`;
    setFecha(fechaFormateada);
  };

  function scrollToBottom() {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth", // Puedes cambiar a 'auto' si prefieres un desplazamiento instantáneo
    });
  }

  const verificarTurnoExistente = (fecha, hora) => {
    const turnoExistente = turnosOcupados.find(
      (turno) => turno.fecha === fecha && turno.hora === hora
    );

    return turnoExistente !== undefined;
  };

  const onSubmit = async (data) => {
    let error = false;

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const todayFormatted = `${year}-${month}-${day}`;

    if (fecha === null || fecha === todayFormatted) {
      error = true;
      Swal.fire({
        text: fecha === null ? "Debe seleccionar una fecha" : "No se puede seleccionar la fecha de hoy",
        icon: "warning",
        confirmButtonText: "Ok",
      });
    }

    if (data.hora === "") {
      error = true;
      Swal.fire({
        text: "Debe seleccionar un horario",
        icon: "warning",
        confirmButtonText: "Ok",
      });
    }

    if (fecha === null) {
      error = true;
      Swal.fire({
        text: "Debe seleccionar una fecha",
        icon: "warning",
        confirmButtonText: "Ok",
      });
    }

    if (
      !(
        /^[a-zA-Z\s]+$/.test(data.nombre) && /^[a-zA-Z\s]+$/.test(data.apellido)
      )
    ) {
      error = true;
      Swal.fire({
        text: "El nombre y el apellido solo pueden contener letras y espacios",
        icon: "warning",
        confirmButtonText: "Ok",
      });
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.mail)) {
      error = true;
      Swal.fire({
        text: "Debe ingresar un mail válido",
        icon: "warning",
        confirmButtonText: "Ok",
      });
    }

    if (!/^\d{8}$/.test(data.dni)) {
      error = true;
      Swal.fire({
        text: "El DNI ingresado no es válido",
        icon: "warning",
        confirmButtonText: "Ok",
      });
    }

    if (
      !/^[0-9]+$/.test(data.peso) ||
      !/^[0-9]+$/.test(data.altura) ||
      !/^[0-9]+$/.test(data.edad)
    ) {
      error = true;
      Swal.fire({
        text: "La edad, el peso y la altura deben ser números enteros",
        icon: "warning",
        confirmButtonText: "Ok",
      });
    }

    if (!error) {
      const turno = await TurnoService.TurnoInsert(
        data.dni,
        `${data.nombre} ${data.apellido}`,
        fecha,
        data.hora
      );

      if (
        turno === "Error en el servidor" ||
        turno === "Ya existe un turno agendado para esa fecha y hora"
      ) {
        Swal.fire({
          text: turno,
          icon: "error",
          confirmButtonText: "Ok",
        });
      } else {
        await crearPreferencia(
          turno.id,
          data.dni,
          data.mail,
          `${data.nombre}-${data.apellido}`,
          data.edad,
          data.peso,
          data.altura,
          data.comentario,
          data.enfermedades,
          data.estudios,
          data.hora,
          fecha
        );
      }
    }
  };

  const crearPreferencia = async (
    turnId,
    dni,
    mail,
    nombreCompleto,
    edad,
    peso,
    altura,
    comentario,
    enfermedades,
    estudios,
    hora,
    fecha
  ) => {
    Swal.fire({
      text: "Para confirmar el turno debe pagar el valor de la consulta, de lo contrario no se registrará la misma",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Pagar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        //generamos la clave de idempotencia
        //const idempotencia = uuid();
        //creamos el pago
        const id = await TurnoService.CrearPreferencia(
          turnId,
          dni,
          mail,
          nombreCompleto,
          edad,
          peso,
          altura,
          comentario,
          enfermedades,
          estudios,
          hora,
          fecha,
          //idempotencia
        );

        if (id === "Error en el servidor") {
          Swal.fire({
            text: id,
            icon: "error",
            confirmButtonText: "Ok",
          });
          const turnoDeleted = await eliminarTurno(turnId, dni);
          reset();
        } else {
          setIdPreferencia(id);
          scrollToBottom();
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        setIdPreferencia("CANCEL");
        const turnoDeleted = await eliminarTurno(turnId, dni);
        reset();
      }
    });
  };

  const handleCancelar = () => {
    Swal.fire({
      text: "¿Seguro que quiere cancelar la operación?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        reset();
        navigate("/home");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  return (
    <div style={{ padding: "30px" }}>
      <h3 className="roboto-titulo">Agendar Turno</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          style={{
            display: "flex",
            gap: "7px",
            justifyContent: "space-between",
          }}
        >
          <div className="mb-3 roboto-texto" style={{ flex: 1 }}>
            <label className="form-label">DNI:</label>
            <input
              type="string"
              className="form-control"
              {...register("dni", { required: true })}
              autoComplete="off"
            />
            {errors.dni && (
              <div className="alert alert-danger" role="alert">
                El dni es un campo necesario
              </div>
            )}
          </div>
          <div className="mb-3 roboto-texto" style={{ flex: 1 }}>
            <label className="form-label">Mail:</label>
            <input
              type="string"
              className="form-control"
              {...register("mail", { required: true })}
              autoComplete="off"
            />
            {errors.mail && (
              <div className="alert alert-danger" role="alert">
                El mail es un campo necesario
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            gap: "7px",
            justifyContent: "space-between",
          }}
        >
          <div className="mb-3 roboto-texto" style={{ flex: 1 }}>
            <label className="form-label">Nombre/s:</label>
            <input
              type="string"
              className="form-control"
              {...register("nombre", { required: true })}
              autoComplete="off"
            />
            {errors.nombre && (
              <div className="alert alert-danger" role="alert">
                El nombre es un campo necesario
              </div>
            )}
          </div>
          <div className="mb-3 roboto-texto" style={{ flex: 1 }}>
            <label className="form-label">Apellido/s:</label>
            <input
              type="text"
              className="form-control"
              {...register("apellido", { required: true })}
              autoComplete="off"
            />
            {errors.apellido && (
              <div className="alert alert-danger" role="alert">
                El apellido es un campo necesario
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            gap: "7px",
            justifyContent: "space-between",
          }}
        >
          <div className="mb-3 roboto-texto" style={{ flex: 1 }}>
            <label className="form-label">Edad:</label>
            <input
              type="string"
              className="form-control"
              {...register("edad", { required: true })}
              autoComplete="off"
            />
            {errors.edad && (
              <div className="alert alert-danger" role="alert">
                La edad es un campo necesario
              </div>
            )}
          </div>
          <div className="mb-3 roboto-texto" style={{ flex: 1 }}>
            <label className="form-label">Peso:</label>
            <input
              type="text"
              className="form-control"
              {...register("peso", { required: true })}
              autoComplete="off"
            />
            {errors.peso && (
              <div className="alert alert-danger" role="alert">
                El peso es un campo necesario
              </div>
            )}
          </div>
          <div className="mb-3 roboto-texto" style={{ flex: 1 }}>
            <label className="form-label">Altura:</label>
            <input
              type="text"
              className="form-control"
              placeholder="(Centímetros)"
              {...register("altura", { required: true })}
              autoComplete="off"
            />
            {errors.altura && (
              <div className="alert alert-danger" role="alert">
                La altura es un campo necesario
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            gap: "7px",
            justifyContent: "space-between",
          }}
        >
          <div className="mb-3 roboto-texto" style={{ flex: 1 }}>
            <label
              className="form-label"
              style={{ paddingBottom: paddingComentario }}
            >
              Comentarios:
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="(opcional)"
              {...register("comentario", { required: false })}
              autoComplete="off"
            />
          </div>
          <div className="mb-3" style={{ flex: 1 }}>
            <label
              className="form-label"
              style={{ paddingBottom: paddingEnfermedades }}
            >
              Enfermedades diagnosticadas:
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="(opcional)"
              {...register("enfermedades", { required: false })}
              autoComplete="off"
            />
          </div>
          <div className="mb-3 roboto-texto" style={{ flex: 1 }}>
            <label className="form-label">
              Estudios previos (laboratorios):
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="(opcional)"
              {...register("estudios", { required: false })}
              autoComplete="off"
            />
          </div>
        </div>

        <div
          style={{
            gap: "7px",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="mb-3 roboto-texto" style={{}}>
            <label style={{ paddingBottom: "6px" }}>Fecha del turno:</label>
            <Datepicker cambioFecha={tomarFecha} />
          </div>
          <div
            className="mb-3 roboto-texto"
            style={{ maxWidth: "220px", margin: "auto" }}
          >
            <label className="form-label">Horario del turno:</label>
            <select className="form-select" {...register("hora")}>
              <option value="">Seleccione un horario</option>
              <option
                value="8:00"
                disabled={verificarTurnoExistente(fecha, "8:00")}
              >
                8:00{" "}
              </option>
              <option
                value="8:30"
                disabled={verificarTurnoExistente(fecha, "8:30")}
              >
                8:30
              </option>
              <option
                value="9:00"
                disabled={verificarTurnoExistente(fecha, "9:00")}
              >
                9:00
              </option>
              <option
                value="9:30"
                disabled={verificarTurnoExistente(fecha, "9:30")}
              >
                9:30
              </option>
              <option
                value="10:00"
                disabled={verificarTurnoExistente(fecha, "10:00")}
              >
                10:00
              </option>
              <option
                value="10:30"
                disabled={verificarTurnoExistente(fecha, "10:30")}
              >
                10:30
              </option>
              <option
                value="11:00"
                disabled={verificarTurnoExistente(fecha, "11:00")}
              >
                11:00
              </option>
              <option
                value="11:30"
                disabled={verificarTurnoExistente(fecha, "11:30")}
              >
                11:30
              </option>
              <option
                value="12:00"
                disabled={verificarTurnoExistente(fecha, "12:00")}
              >
                12:00
              </option>
              <option
                value="12:30"
                disabled={verificarTurnoExistente(fecha, "12:30")}
              >
                12:30
              </option>
              <option
                value="13:00"
                disabled={verificarTurnoExistente(fecha, "13:00")}
              >
                13:00
              </option>
            </select>
          </div>
        </div>
        <div className="roboto-texto">
          <button type="submit" className="btn btn-success">
            Confirmar
          </button>
          <button
            type="button"
            className="btn btn-danger mx-2"
            onClick={handleCancelar}
          >
            Cancelar
          </button>
        </div>
      </form>
      {idPreferencia && idPreferencia !== "CANCEL" && (
        <div
          id="wallet_container"
          style={{
            maxWidth: "500px",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Wallet
            initialization={{ preferenceId: idPreferencia }}
            customization={{ texts: { valueProp: "smart_option" } }}
          />
        </div>
      )}
    </div>
  );
}

export default Agendar;
