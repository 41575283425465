import axios from "axios";

const url = "https://nataliaperez-backend.onrender.com";
const price = 8000;

const TurnosGetAll = async () => {
  try {
    const res = await axios.get(`${url}/api/turnos`);
    return res.data;
  } catch (error) {
    return "Error en el servidor";
  }
};

const TurnoGetByIdYDni = async (id, dni) => {
  try {
    const res = await axios.get(`${url}/api/turnos/${dni}/${id}`);
    return res.data
  } catch (error) {
    return "Error en el servidor"
  }
}

const TurnoInsert = async (dni, nombreCliente, fecha, hora) => {
  try {
    const res = await axios({
      method: "post",
      url: `${url}/api/turnos`,
      headers: {},
      data: {
        dni: dni,
        nombreCliente: nombreCliente,
        fecha: fecha,
        hora: hora,
      },
    });
    return res.data;
  } catch (error) {
    return "Error en el servidor";
  }
};

const TurnoDelete = async (id, dni) => {
  try {
    const turnoEliminado = await axios.delete(`${url}/api/turnos/${dni}/${id}`);
    return turnoEliminado;
  } catch (error) {
    return "Error en el servidor";
  }
};

const TurnoUpdate = async (id, dni, turnoModificado) => {
  try {
    const res = await axios.put(`${url}/api/turnos/${dni}/${id}`, turnoModificado)
    return res.data
  } catch (error) {
    return "Error en el servidor"
  }
}

const CrearPreferencia = async (
  turnId,
  dni,
  mail,
  nombreCompleto,
  edad,
  peso,
  altura,
  comentario,
  enfermedades,
  estudios,
  hora,
  fecha,
  IdempotencyKey
) => {
  try {
    const res = await axios({
      method: "post",
      url: `${url}/create-preference`,
      headers: {"X-Idempotency-Key": IdempotencyKey},
      data: {
        title: "Consulta virtual de nutrición",
        quantity: 1,
        price: price,
        dni: dni,
        mail: mail,
        nombreCompleto: nombreCompleto,
        edad: edad,
        peso: peso,
        altura: altura,
        coment: comentario,
        disease: enfermedades,
        labs: estudios,
        hour: hora,
        date: fecha,
        turnId: turnId,
      },
    });

    return res.data.id;
  } catch (error) {
    console.log(error);
    return "Error en el servidor";
  }
};

const Notification = async (datosMensaje) => {
  try {
    const result = await axios({
      method: "post",
      url: `${url}/api/notification`,
      headers: {},
      data: {
        mail: datosMensaje.mail,
        htmlMessage: datosMensaje.htmlMessage
      },
    });
    return result

  } catch (error) {
    return "ERROR";
  }
};

const TurnoService = {
  TurnosGetAll,
  TurnoGetByIdYDni,
  TurnoInsert,
  TurnoDelete,
  TurnoUpdate,
  CrearPreferencia,
  Notification,
};

export default TurnoService;
